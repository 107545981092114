<template>
  <section>
    <van-nav-bar left-arrow @click-left='this.$router.push("/")' title="设置"></van-nav-bar>
  </section>

  <section class="van-doc-demo-section demo-dialog">
    <div class="van-doc-demo-block">

      <div class="van-doc-demo-block__title">
        您好：{{ this.username }}
      </div>

      <div class="van-doc-demo-block__card">
        <van-row>
          <van-cell is-link title="退出" @click="logout" />
        </van-row>
      </div>
    </div>

  </section>

  <foot></foot>

</template>


<script>
import { ref } from 'vue';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import foot from '@/components/Foot'


export default {
  components:{foot},
  data() {
    return {
      username:""
    }
  },
  mounted() {
    this.username = localStorage.getItem("username")
  },
  setup() {
    const show = ref(false);
    const actions = [
      { name: '选项一' },
      { name: '选项二' },
      { name: '选项三' },
    ];
    const onCancel = () => Toast('取消');

    return {
      show,
      actions,
      onCancel,
    };
  },
  methods: {
    logout(){
      Dialog.confirm({
        title: '确认退出',
      })
          .then(() => {
            // on confirm
            localStorage.removeItem('auth')
            localStorage.removeItem('username')
            this.$router.push('login')
          })
    }
  }
}

</script>

<style scoped>
@import 'https://vant-contrib.gitee.io/vant/assets/mobile.6dc0f7cb.css'; /*引入公共样式*/


</style>